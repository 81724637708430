import "@styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { ThemeProvider } from "next-themes";
import TopBarProgress from "react-topbar-progress-indicator";
import { useEffect, useState } from "react";
import { Router, useRouter } from "next/router";
import * as ga from "@lib/ga";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const [progress, setProgress] = useState(false);
  const router = useRouter();

  Router.events.on("routeChangeStart", () => setProgress(true));
  Router.events.on("routeChangeComplete", () => setProgress(false));
  TopBarProgress.config({
    barColors: {
      "0": "#9333ea",
      "0.5": "#db2777",
      "1.0": "#c026d3",
    },
    shadowBlur: 5,
  });
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return (
    <SessionProvider session={session}>
      <ThemeProvider attribute="class">
        {progress && <TopBarProgress />}
      </ThemeProvider>
      <Component {...pageProps} />
    </SessionProvider>
  );
}
